<br><br><br><br><br><br>
<section>
    <!-- CNG header Images -->
    <!-- *ngIf="productEvent == 'cng' && english == 'Meerut'" -->
    <div id="cng" class="img-padding">
        <div class="hero-image" style=" background-image: url(../../assets/product/images/GAIL_Gas_website_CNG_Eng.jpg); background-position: top;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 250px;
        ">
        </div>
    </div>

    <!-- <div id="cng" *ngIf="productEvent == 'cng' && english == 'मेरठ'"  class="img-padding">
        <div class="hero-image" style=" background-image: url(../../../assets/product/images/GAIL_Gas_website_CNG_Hindi.jpg); background-position: top;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            height: 250px;
        ">
        </div>
    </div> -->

    <section>
        <div style="margin: 0px 40px 10px 40px;">
            <div class="complhead">
                <div style="cursor: pointer;" (click)="gotomenu('formmenu')" routerLink="/eoi/expression-interest"><svg
                        xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#ffffff"
                        class="bi bi-house-door" viewBox="0 0 16 16">
                        <path
                            d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146ZM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5Z" />
                    </svg></div>
                <div style="width: 90%;">
                    <h4>CNG Station Details</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <form [formGroup]="personalForm">
                        <div class="row">
                            <div class="col-md-12 d-flex justify-content-between mt-3">
                                <label class="name_Auditors">Location Applied
                                    <span style="color: red;">*</span>
                                </label>
                                <!-- <mat-form-field class="inputformFields" appearance="outline">
                                <input matInput placeholder="Location Applied" formControlName="Location_Applied" >
                            </mat-form-field> -->
                                <div class="inputFormSet">
                                    <mat-form-field class="inputformFields" appearance="outline">
                                        <mat-select formControlName="Location_Applied"
                                            placeholder="Select Location Applied" disableOptionCentering>
                                            <mat-option *ngFor="let state of geoGraphy" [value]="state">
                                                {{ state.ga_nm }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <span class="errorMessage" *ngIf="personalForm.get('Location_Applied').invalid">
                                        Location is required or invalid.
                                    </span>
                                </div>

                            </div>
                            <div class="col-md-12 d-flex justify-content-between">

                                <label class="name_Auditors">Name
                                    <span style="color: red;">*</span>
                                </label>
                                <div class="inputFormSet">
                                    <mat-form-field class="inputformFields" appearance="outline">
                                        <input matInput placeholder="Enter Name" formControlName="Name">
                                    </mat-form-field>
                                    <span class="errorMessage"
                                        *ngIf="personalForm.get('Name').invalid && personalForm.get('Name').touched">
                                        Name is required and must be alphabet only.
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-12 d-flex justify-content-between">
                                <label class="name_Auditors">Father Name
                                    <span style="color: red;">*</span>
                                </label>
                                <div class="inputFormSet">
                                    <mat-form-field class="inputformFields" appearance="outline">
                                        <input matInput placeholder="Enter Father Name" formControlName="Father_Name">
                                    </mat-form-field>
                                    <span class="errorMessage"
                                        *ngIf="personalForm.get('Father_Name').invalid && personalForm.get('Father_Name').touched">
                                        Father name is required and must be alphabet only.
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-12 d-flex justify-content-between">
                                <label class="name_Auditors">Date Of Birth
                                    <span style="color: red;">*</span>
                                </label>
                                <div class="inputFormSet">
                                    <mat-form-field class="inputformFields" (click)="picker.open()"
                                        appearance="outline">
                                        <input formControlName="Date_Birth" matInput [matDatepicker]="picker"
                                            placeholder="Select Date Of Birth" style="cursor: pointer;" readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                    <span class="errorMessage"
                                        *ngIf="personalForm.get('Date_Birth').invalid && personalForm.get('Date_Birth').touched">
                                        Date of birth is required.
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-12 d-flex justify-content-between">
                                <label class="name_Auditors">Gender
                                    <span style="color: red;">*</span>
                                </label>
                                <div class="inputFormSet">
                                    <mat-form-field class="inputformFields" appearance="outline">
                                        <mat-select formControlName="Gender" placeholder="Select Gender"
                                            disableOptionCentering>
                                            <mat-option *ngFor="let state of Gender_Status" [value]="state">
                                                {{ state.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <span class="errorMessage"
                                        *ngIf="personalForm.get('Gender').invalid && personalForm.get('Gender').touched">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-12 d-flex justify-content-between">
                                <label class="name_Auditors">Marital Status
                                    <!-- <span style="color: red;">*</span> -->
                                </label>
                                <div class="inputFormSet">
                                    <mat-form-field class="inputformFields" appearance="outline">
                                        <input matInput placeholder="Enter Marital Status"
                                            formControlName="Marital_Status">
                                    </mat-form-field>
                                    <span class="errorMessage"
                                        *ngIf="personalForm.get('Marital_Status').invalid && personalForm.get('Marital_Status').touched">
                                        This field is required or invalid.
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-12 d-flex justify-content-between">
                                <label class="name_Auditors">Nationality
                                    <span style="color: red;">*</span>
                                </label>
                                <div class="inputFormSet">
                                    <mat-form-field class="inputformFields" appearance="outline">
                                        <input matInput placeholder="Enter Nationality" formControlName="Nationality">
                                    </mat-form-field>
                                    <span class="errorMessage"
                                        *ngIf="personalForm.get('Nationality').invalid && personalForm.get('Nationality').touched">
                                        This field is required or invalid.
                                    </span>
                                </div>

                            </div>

                            <div class="col-md-12 d-flex justify-content-between">
                                <label class="name_Auditors">Email<span style="color: red;">*</span></label>
                                <div class="inputFormSet">
                                    <mat-form-field class="inputformFields" appearance="outline">
                                        <input matInput placeholder="Enter Email" formControlName="Email">
                                    </mat-form-field>
                                    <span class="errorMessage"
                                        *ngIf="personalForm.get('Email').invalid && personalForm.get('Email').touched">
                                        This field is required or invalid.
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-12 d-flex justify-content-between">
                                <label class="name_Auditors">Educational Qualification
                                    <span style="color: red;">*</span>
                                </label>
                                <div class="inputFormSet">
                                    <mat-form-field class="inputformFields" appearance="outline">
                                        <input matInput placeholder="Enter Educational Qualification"
                                            formControlName="Educational_Qualification">
                                    </mat-form-field>
                                    <span class="errorMessage"
                                        *ngIf="personalForm.get('Educational_Qualification').invalid && personalForm.get('Educational_Qualification').touched">
                                        Educational Qualification must be alphabet only.
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-12 d-flex justify-content-between">
                                <label class="name_Auditors">Current Address:
                                    <span style="color: red;">*</span>
                                </label>
                                <div class="inputFormSet">
                                    <mat-form-field class="inputformFields" appearance="outline">
                                        <textarea matInput placeholder="Enter Current Address"
                                            formControlName="Current_Address"></textarea>
                                    </mat-form-field>
                                    <span class="errorMessage"
                                        *ngIf="personalForm.get('Current_Address').invalid && personalForm.get('Current_Address').touched">
                                        This field is required or invalid.
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-4 d-flex justify-content-between">
                                <label class="name_Auditors">District
                                    <span style="color: red;">*</span>
                                </label>
                                <div class="inputFormSet">
                                    <mat-form-field class="inputformFields" appearance="outline">
                                        <input matInput placeholder="Enter District" formControlName="District">
                                    </mat-form-field>
                                    <span class="errorMessage"
                                        *ngIf="personalForm.get('District').invalid && personalForm.get('District').touched">
                                        This field is required or invalid.
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-4 d-flex justify-content-between">
                                <label class="name_Auditors">Pin Code
                                    <span style="color: red;">*</span>
                                </label>
                                <div class="inputFormSet">
                                    <mat-form-field class="inputformFields" appearance="outline">
                                        <input matInput placeholder="Enter Pin Code" formControlName="Pin_Code">
                                    </mat-form-field>
                                    <span class="errorMessage"
                                        *ngIf="personalForm.get('Pin_Code').invalid && personalForm.get('Pin_Code').touched">
                                        Pin code must be 6 digit only.
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-4 d-flex justify-content-between">
                                <label class="name_Auditors">State
                                    <span style="color: red;">*</span>
                                </label>
                                <div class="inputFormSet">
                                    <mat-form-field class="inputformFields" appearance="outline">
                                        <input matInput placeholder="Enter State" formControlName="State">
                                    </mat-form-field>
                                    <span class="errorMessage"
                                        *ngIf="personalForm.get('State').invalid && personalForm.get('State').touched">
                                        This field is required or invalid.
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-12 d-flex justify-content-between">
                                <label class="name_Auditors">PAN Number
                                    <span style="color: red;">*</span>
                                </label>
                                <div class="inputFormSet">
                                    <mat-form-field class="inputformFields" appearance="outline">
                                        <input matInput placeholder="Enter PAN Number" formControlName="PAN_Number">
                                    </mat-form-field>
                                    <span class="errorMessage"
                                        *ngIf="personalForm.get('PAN_Number').invalid && personalForm.get('PAN_Number').touched">
                                        This field is required or invalid.
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-12 d-flex justify-content-between">
                                <label class="name_Auditors">Aadhar No.
                                    <span style="color: red;">*</span>
                                </label>
                                <div class="inputFormSet">
                                    <mat-form-field class="inputformFields" appearance="outline">
                                        <input matInput placeholder="Enter Aadhar No" formControlName="Aadharr_No">
                                    </mat-form-field>
                                    <span class="errorMessage"
                                        *ngIf="personalForm.get('Aadharr_No').invalid && personalForm.get('Aadharr_No').touched">
                                        This field is required or invalid.
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-12 d-flex justify-content-between" style="margin-bottom: 10px;">
                                <label class="name_Auditors">Application Form with Proof of Age, Qualification &
                                    Application Fee Receipt<span style="color: red;">*</span></label>
                                <div class="d-flex">
                                    <div class="inputFileSet">
                                        <!-- <button mat-raised-button class="submitbtn_main" (click)="fileInputOne.click()">Upload File</button> -->
                                        <input class="form-control file" formControlName="Upload_report_One" type="file"
                                            (change)="onFileSelected($event, 1)" #fileInputOne accept=".pdf">

                                        <div *ngIf="filePreviewOne"
                                            style="margin-left: 25px;margin-top: auto; margin-bottom: auto;">
                                            <img *ngIf="filePreviewOne.type.startsWith('image')"
                                                [src]="filePreviewOne.url" alt="File 1 Preview"
                                                style="max-width: 200px; max-height: 200px;">
                                            <p *ngIf="filePreviewOne.type.startsWith('application/pdf')"
                                                style="color: red;">{{uploadFileOne.name}}</p>
                                        </div>
                                        <span class="errorMessage1"
                                            *ngIf="personalForm.get('Upload_report_One').invalid && personalForm.get('Upload_report_One').touched">
                                            This field is required or invalid.
                                        </span>
                                    </div>

                                </div>

                            </div>
                            <div class="col-md-12 d-flex justify-content-between" style="margin-bottom: 10px;">
                                <label class="name_Auditors">Checklist & Annexures ( A, B, C, D, E , F & G as
                                    applicable) <span style="color: red;">*</span></label>
                                <div class="d-flex">

                                    <div class="inputFileSet">
                                        <!-- <button mat-raised-button class="submitbtn_main" (click)="fileInputTwo.click()">Upload File</button> -->
                                        <input class="form-control file" formControlName="Upload_report_Two" type="file"
                                            (change)="onFileSelected($event, 2)" #fileInputTwo accept=".pdf">
                                        <div *ngIf="filePreviewTwo"
                                            style="margin-left: 25px;margin-top: auto; margin-bottom: auto;">
                                            <img *ngIf="filePreviewTwo.type.startsWith('image')"
                                                [src]="filePreviewTwo.url" alt="File 2 Preview"
                                                style="max-width: 200px; max-height: 200px;">
                                            <p *ngIf="filePreviewTwo.type.startsWith('application/pdf')"
                                                style="color: red;">{{uploadFileTwo.name}}</p>
                                        </div>
                                        <span class="errorMessage1"
                                            *ngIf="personalForm.get('Upload_report_Two').invalid && personalForm.get('Upload_report_Two').touched">
                                            This field is required or invalid.
                                        </span>
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-12 d-flex justify-content-between" style="margin-bottom: 10px;">
                                <label class="name_Auditors">Information Brochure, signed on each page <span
                                        style="color: red;">*</span></label>
                                <div class="d-flex">
                                    <div class="inputFileSet">
                                        <!-- <button mat-raised-button class="submitbtn_main" (click)="fileInputThree.click()">Upload File</button> -->
                                        <input class="form-control file" formControlName="Upload_report_three"
                                            type="file" (change)="onFileSelected($event, 3)" #fileInputThree
                                            accept=".pdf">
                                        <div *ngIf="filePreviewThree"
                                            style="margin-left: 25px;margin-top: auto; margin-bottom: auto;">
                                            <img *ngIf="filePreviewThree.type.startsWith('image')"
                                                [src]="filePreviewThree.url" alt="File 3 Preview"
                                                style="max-width: 200px; max-height: 200px;">
                                            <p *ngIf="filePreviewThree.type.startsWith('application/pdf')"
                                                style="color: red;">{{uploadFileThree.name}}</p>
                                        </div>
                                        <span class="errorMessage1"
                                            *ngIf="personalForm.get('Upload_report_three').invalid && personalForm.get('Upload_report_three').touched">
                                            This field is required or invalid.
                                        </span>
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-12 d-flex justify-content-between" style="margin-bottom: 10px;">
                                <label class="name_Auditors">Land Related Documents <span
                                        style="color: red;">*</span></label>
                                <div class="d-flex">
                                    <div class="inputFileSet">
                                        <!-- <button mat-raised-button class="submitbtn_main" (click)="fileInputFour.click()">Upload File</button> -->
                                        <input class="form-control file" formControlName="Upload_report_four"
                                            type="file" (change)="onFileSelected($event, 4)" #fileInputFour
                                            accept=".pdf">
                                        <div *ngIf="filePreviewFour"
                                            style="margin-left: 25px;margin-top: auto; margin-bottom: auto;">
                                            <img *ngIf="filePreviewFour.type.startsWith('image')"
                                                [src]="filePreviewFour.url" alt="File 4 Preview"
                                                style="max-width: 200px; max-height: 200px;">
                                            <p *ngIf="filePreviewFour.type.startsWith('application/pdf')"
                                                style="color: red;">{{uploadFileFour.name}}</p>
                                        </div>
                                        <span class="errorMessage1"
                                            *ngIf="personalForm.get('Upload_report_four').invalid && personalForm.get('Upload_report_four').touched">
                                            This field is required or invalid.
                                        </span>
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-12 d-flex justify-content-between" style="margin-bottom: 10px;">
                                <label class="name_Auditors">Other Documents<span style="color: red;">*</span></label>
                                <div class="d-flex">
                                    <div class="inputFileSet">
                                        <!-- <button mat-raised-button class="submitbtn_main" (click)="fileInputFive.click()">Upload File</button> -->
                                        <input class="form-control file" formControlName="Upload_report_five"
                                            type="file" (change)="onFileSelected($event, 5)" #fileInputFive
                                            accept=".pdf">
                                        <div *ngIf="filePreviewFive"
                                            style="margin-left: 25px;margin-top: auto; margin-bottom: auto;">
                                            <img *ngIf="filePreviewFive.type.startsWith('image')"
                                                [src]="filePreviewFive.url" alt="File 5 Preview"
                                                style="max-width: 200px; max-height: 200px;">
                                            <p *ngIf="filePreviewFive.type.startsWith('application/pdf')"
                                                style="color: red;">{{uploadFileFive.name}}</p>
                                            <!-- [href]="filePreviewFive.url" target="_blank" -->
                                        </div>
                                        <span class="errorMessage1"
                                            *ngIf="personalForm.get('Upload_report_five').invalid && personalForm.get('Upload_report_five').touched">
                                            This field is required or invalid.
                                        </span>
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-12 d-flex justify-content-between" style="margin-bottom: 10px;">
                                <label class="name_Auditors">Mobile Number<span style="color: red;">*</span></label>
                                <div class="inputFormSet">
                                    <mat-form-field class="inputformFields" appearance="outline">
                                        <input #num class="mob" style="position: relative;" matInput
                                            placeholder="Enter Mobile Number" formControlName="Mobile_Number"
                                            maxlength="10">
                                        <!-- <span
                                        style="background-color: #0295C1; padding: 13px 19px; width: 50px;  cursor: pointer; position: absolute; right: -10px; top: -7px; border-radius: 0 5px 5px 0;"
                                        class="otpbtn df" (click)="getotp()" [ngClass]="{ 'disabled': buttonDisabled }">
                                        <img src="../../assets/submitForm/otpgen.svg" alt="otp" width="14px">
                                    </span> -->
                                        <!-- <span class="maxcar" style="position: absolute;bottom: -20px;left: -6px;">
                                        <span>*</span> Click on
                                        Blue arrow to get an OTP</span> -->
                                        <!-- <span style="position: absolute; top: 8px; right: -60px;" (click)="getotp()"
                                        class="resend" *ngIf="timerInterval && timer == 0">Resend</span> -->
                                        <!-- <span class="timer"
                                        style="color:#0784C7; position: absolute; top: 8px; right: -60px;"
                                        *ngIf="timerInterval && timer > 0">{{
                                        formatTime(timer)
                                        }}</span> -->
                                    </mat-form-field>
                                    <span class="errorMessage1"
                                        *ngIf="personalForm.get('Mobile_Number').invalid && personalForm.get('Mobile_Number').touched">
                                        This field is required or invalid.
                                    </span>
                                </div>
                            </div>
                            <!-- <div class="col-md-12 d-flex justify-content-between" style="margin-bottom: 10px;">
                                <label class="name_Auditors">OTP<span style="color: red;">*</span></label>
                                <div class="inputFormSet">
                                    <mat-form-field class="inputformFields" appearance="outline">
                                        <input type="password" #num matInput placeholder="Enter OTP"
                                            formControlName="otp" maxlength="6" [readonly]="EnterOtp_Send == false"
                                            [ngClass]="{'disabled-button': EnterOtp_Send == false}">
                                    </mat-form-field>
                                </div>
                            </div> -->
                        </div>
                        <div class="d-flex justify-content-center mt-4">
                            <button mat-raised-button class="submitbtn" (click)="SubmitClick()">Submit</button>
                        </div>
                    </form>
                </div>
                <div class="col-md-4">
                    <img src="../../assets/product/images/CNG_1.svg" alt="CNG Logo" style="width:100%;">
                </div>
            </div>
        </div>
    </section>
</section>

<div id="overlay">
    <div class="loader"></div>
</div>