<br><br><br><br>
<div class="container-fluid" *ngIf="schemeData">
    <div>
        <img src="../../assets/promotionpdf/banner1.jpeg" alt="Image description"
            style="width: 100%; height: 100%; object-fit: contain;">
    </div>

    <div class="row mt-3">
        <div class="col-md-1"></div>
        <div class="col-md-10">
            <div>
                <h3 class="overview-heading">
                    <img src="../../../assets/gileModule/overview/overview-leave.svg" class="leave-style">
                    {{schemeData['pngTitle'] | translate}}
                </h3>
                <p class="txt">
                    {{schemeData['pngtxt'] | translate}}
                </p>
            </div>


            <div class="row">

                <div class="col-md-12">
                    <ul class="listMarginTop" *ngFor="let item of schemeData['pngSubText']">
                        <li>{{ item.text }}</li>                        
                    </ul>
                </div>

                <div class="col-md-2"></div>
            </div>

        </div>

    </div>

    <!-- Week Cards -->
    <div class="row ">
        <div class="col-md-1"></div>
        <div class="col-md-10">

            <h2 *ngIf="Title" class="overview-heading mt-2"><img  src="../../../assets/gileModule/overview/overview-leave.svg" class="leave-style">&nbsp; {{schemeData['pngTitle2'] | translate}} </h2>
            <div *ngIf="ModalBoolean">
                <h2 class="overview-heading mt-2">
                    <img src="../../../assets/gileModule/overview/overview-leave.svg" class="leave-style">Glimpse of the Event {{Week_Name}} in {{ModalName}} 
                </h2>
            </div>
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-12">
                    <ul *ngFor="let item of schemeData['subScheme']">
                         
                        <li>{{ item.text }}</li>
                    </ul>


                </div>
                <div class="col-md-12 mb-3">
                    <table class="fuelDetails">
                        <tr>
                            <th>S/N</th>
                            <th>Particulars</th>
                            <th>No Of Cards </th>
                            <th>Promotional Scheme</th>
                        </tr>
                        <tr *ngFor="let item of schemeData['schemeTable1']">
                            <td>{{item.id}}</td>
                            <td class="paddZero">
                                <table class="tabble tabBorderBottom tabBorderTop">
                                    <tr>
                                        <td class="widthFirst" *ngFor="let si of item.particular">{{si.text}}</td>
                                    </tr>
                                </table>
                            </td>
                            <td>{{item.no_of_card}}</td>
                            <td>{{ item.pramotional_scheem }}</td>
                        </tr>
                        

                    </table>

                </div>
                <div class="col-md-12">
                    <p class="offerPara mb-3 paddLeft25"> {{ schemeData['schemeText1'] | translate  }} </p>
                    <table class="fuelDetails mb-3">
                        <tr>
                            <th>Category</th>
                            <th>Usage Limit (Rs)</th>
                            <th>Time-Limit</th>
                        </tr>
                        <tr *ngFor="let item of schemeData['schemeTable2']">
                            <td>{{item.category}}</td>
                            <td>{{item.useseg_limit}}</td>
                            <td>{{item.time_limit}}</td>
                        </tr>
                        
                    </table>
                </div>
                <div class="col-md-2"></div>
            </div>
            <div class="col-md-12">
                <p class="offerPara paddLeft20">{{schemeData['schemeText2'] | translate}}</p>
            </div>
            <div class="col-md-12">
                <h2 *ngIf="Title" class="overview-heading mt-2"><img
                        src="../../../assets/gileModule/overview/overview-leave.svg" class="leave-style">&nbsp;
                    {{schemeData['pngTitle3'] | translate}}
                </h2>
            </div>

            <div class="col-md-12">
                <ul class="lsitBlc">
                    <li *ngFor="let lii of schemeData['sub_avail_offer']">{{lii.id}}. {{lii.text}}</li>
                </ul>
            </div>
            <div class="col-md-12">
                <p><b>{{ schemeData['offer1'] | translate }}</b></p>

                <p [innerHTML]="schemeData['offer2'] | translate"></p>

                <ul class="terms-list"> 
                    <li *ngFor="let item of schemeData['subMenuList']"><a href={{item.fileLink}} target="_blank">{{item.text}} </a> </li>
                </ul>
            </div>
        </div>
        <div class="col-md-1"></div>
    </div>

    <!-- Display Images -->
    <div class="row mt-5 mb-4" id="NavigateModal" *ngIf="ModalBoolean">
        <div class="col-md-1"></div>
        <div class="col-md-10">
            <!-- <div>
                <h2 class="overview-heading mt-2"><img src="../../../assets/gileModule/overview/overview-leave.svg"
                        class="leave-style">Glimpse of the Event {{Week_Name}} in {{ModalName}} </h2>
            </div> -->
            <div class="row">
                <div class="col-md-4 mb-5" *ngFor="let ele of ImagesList_data;let i = index" style="padding: 3%;">
                    <img *ngIf="!ele.url.endsWith('.mp4')" [src]="ele.url" alt="" class="imgCard1"
                        (click)="Modal(ele, ImagesList_data)">
                    <video #videoPlayer (play)="handlePlay($event.target)" controls *ngIf="ele.url.endsWith('.mp4')"
                        width="100%" height="250px">
                        <source src="{{ele.url}}" type="video/mp4" />
                    </video>
                    <br>
                </div>
            </div>
        </div>
        <div class="col-md-1"></div>
    </div>

    <!-- Modal -->
    <div>
        <div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1"
            aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <!-- <span><img src="../../assets/news-media/downoad-arrow.svg" alt="" style="cursor: pointer;"
                        (click)="ImgDown()"></span> -->
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div id="carouselExampleControlsNoTouching" class="carousel slide" data-touch="false"
                            data-interval="false">
                            <div class="carousel-inner" *ngIf="ImageList && ImageList.length > 0">
                                <div class="carousel-item" *ngFor="let image of ImageList; let i = index"
                                    [class.active]="i === clickedImageIndex">
                                    <img *ngIf="!image.url.endsWith('.mp4')" [src]="image.url" alt="" class="imgCard2">
                                    <video *ngIf="image.url.endsWith('.mp4')" controls width="100%" height="250px">
                                        <source src="{{image.url}}" type="video/mp4" />
                                    </video>
                                </div>
                            </div>

                            <button class="carousel-control-prev" type="button"
                                data-target="#carouselExampleControlsNoTouching" data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span>
                            </button>
                            <button class="carousel-control-next" type="button"
                                data-target="#carouselExampleControlsNoTouching" data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Next</span>
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>


</div>